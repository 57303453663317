exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/Company/Careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-contact-us-js": () => import("./../../../src/pages/Company/Contact-us.js" /* webpackChunkName: "component---src-pages-company-contact-us-js" */),
  "component---src-pages-company-faq-js": () => import("./../../../src/pages/Company/FAQ.js" /* webpackChunkName: "component---src-pages-company-faq-js" */),
  "component---src-pages-company-legal-documents-js": () => import("./../../../src/pages/Company/Legal-documents.js" /* webpackChunkName: "component---src-pages-company-legal-documents-js" */),
  "component---src-pages-company-privacy-policy-js": () => import("./../../../src/pages/Company/Privacy_Policy.js" /* webpackChunkName: "component---src-pages-company-privacy-policy-js" */),
  "component---src-pages-company-regulations-offices-js": () => import("./../../../src/pages/Company/RegulationsOffices.js" /* webpackChunkName: "component---src-pages-company-regulations-offices-js" */),
  "component---src-pages-company-terms-and-conditions-js": () => import("./../../../src/pages/Company/TermsAndConditions.js" /* webpackChunkName: "component---src-pages-company-terms-and-conditions-js" */),
  "component---src-pages-company-who-we-are-js": () => import("./../../../src/pages/Company/Who-we-are.js" /* webpackChunkName: "component---src-pages-company-who-we-are-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-economic-calendar-js": () => import("./../../../src/pages/insights/EconomicCalendar.js" /* webpackChunkName: "component---src-pages-insights-economic-calendar-js" */),
  "component---src-pages-insights-glossary-js": () => import("./../../../src/pages/insights/Glossary.js" /* webpackChunkName: "component---src-pages-insights-glossary-js" */),
  "component---src-pages-markets-cfds-js": () => import("./../../../src/pages/Markets/cfds.js" /* webpackChunkName: "component---src-pages-markets-cfds-js" */),
  "component---src-pages-markets-exchange-traded-futures-js": () => import("./../../../src/pages/Markets/ExchangeTradedFutures.js" /* webpackChunkName: "component---src-pages-markets-exchange-traded-futures-js" */),
  "component---src-pages-markets-forex-js": () => import("./../../../src/pages/Markets/Forex.js" /* webpackChunkName: "component---src-pages-markets-forex-js" */),
  "component---src-pages-markets-index-js": () => import("./../../../src/pages/Markets/index.js" /* webpackChunkName: "component---src-pages-markets-index-js" */),
  "component---src-pages-register-demo-js": () => import("./../../../src/pages/register/demo.js" /* webpackChunkName: "component---src-pages-register-demo-js" */),
  "component---src-pages-register-ib-js": () => import("./../../../src/pages/register/ib.js" /* webpackChunkName: "component---src-pages-register-ib-js" */),
  "component---src-pages-register-live-js": () => import("./../../../src/pages/register/live.js" /* webpackChunkName: "component---src-pages-register-live-js" */),
  "component---src-pages-trading-accounts-js": () => import("./../../../src/pages/Trading/Accounts.js" /* webpackChunkName: "component---src-pages-trading-accounts-js" */),
  "component---src-pages-trading-deposit-withdrawal-js": () => import("./../../../src/pages/Trading/Deposit-Withdrawal.js" /* webpackChunkName: "component---src-pages-trading-deposit-withdrawal-js" */),
  "component---src-pages-trading-mt-4-desktop-js": () => import("./../../../src/pages/Trading/MT4Desktop.js" /* webpackChunkName: "component---src-pages-trading-mt-4-desktop-js" */),
  "component---src-pages-trading-mt-4-mobile-js": () => import("./../../../src/pages/Trading/MT4Mobile.js" /* webpackChunkName: "component---src-pages-trading-mt-4-mobile-js" */),
  "component---src-pages-trading-webtrader-js": () => import("./../../../src/pages/Trading/Webtrader.js" /* webpackChunkName: "component---src-pages-trading-webtrader-js" */)
}

